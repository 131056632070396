$black: rgb(34, 40, 49);
$darker: rgb(57, 62, 70);
$darker-hover: rgba(57, 62, 70, 0.125);
$dark-grey: rgb(165, 165, 165);
$grey: rgb(238, 238, 238);
$teal: rgb(0, 173, 181);
$green: rgb(57, 221, 92);
$green-hover: rgba(37, 180, 68, 0.747);
$categ-box-shadow: 0 0 10px $darker-hover;
$main: rgb(241, 186, 65);
$main-darker: rgb(182, 140, 50);

.quiz-display {
    height: 70vh;
    margin: 0 auto;
    background-position: 0;
    background-repeat: no-repeat;
    background-size: cover;

    .front {
        width: 100%;
        height: 100%;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .options {
        width: 70%;
        display: flex;
        flex-wrap: wrap;
    }

    .current-stats {
        position: absolute;
        background-color: $grey;
        width: 100%;
        height: 70px;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 500;

        #quest-num {
            font-size: 1.6rem;
            margin: 0 auto;
        }
    }

    #question {
        font-size: 4vh;
        line-height: 15vh;
        text-align: center;
        margin: 20px;
        width: 80%;
        height: 15vh;
        font-weight: 500;
        background-color: $grey;
        border-radius: 10px;
        padding: 2vh;
        margin-top: 70px;
        color: rgb(0, 0, 0);
        overflow-y: scroll;
    }

    @media only screen and (max-width:700px) {
        .options button {
            transform: scale(0.8);
        }

        #question {
            font-size: 2vh;
        }
    }

    @media only screen and (max-width:500px) {
        .options {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
        }

        .options button {
            width: 80%;
            font-size: 1.1rem;
            height: 7vh;
        }

        #question {
            font-size: 3vh;
            width: 100%;
            border-radius: 0px;
        }
    }
}