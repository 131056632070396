$black: rgb(34, 40, 49);
$darker: rgb(57, 62, 70);
$darker-hover: rgba(57, 62, 70, 0.125);
$dark-grey: rgb(165, 165, 165);
$grey: rgb(238, 238, 238);
$teal: rgb(0, 173, 181);
$green: rgb(57, 221, 92);
$green-hover: rgba(37, 180, 68, 0.747);
$categ-box-shadow: 0 0 10px $darker-hover;
$main: rgb(241, 186, 65);
$main-darker: rgb(182, 140, 50);

.category {
    height: 400px;
    width: 400px;
    flex: 30%;
    margin: 20px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .img {
        height: 160px;
        width: 350px;
        background-position: 0;
        background-size: cover;
        background-repeat: no-repeat;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        box-shadow: $categ-box-shadow;
    }

    .info {
        background: white;
        height: 220px;
        width: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        box-shadow: $categ-box-shadow;

        span {
            margin: 20px auto;
            font-size: 1.5rem;
        }

        button {
            position: relative;
            height: 50px;
            width: 130px;
            margin: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $main-darker;
            background-color: $main;
            transition: .3s ease;
            overflow: hidden;
            border-radius: 10px;
            cursor: pointer;
            text-transform: uppercase;
            font-size: 1.1rem;
            letter-spacing: 1px;
            color: black;
        }

        button::before,
        button::after {
            position: absolute;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
        }

        button:after {
            left: -100%;
            clip-path: polygon(10% 0, 70% 0, 90% 100%, 30% 100%);
            background-color: rgba(255, 255, 255, 0.6);
            transition: all 300ms ease;
        }

        button:hover::after {
            left: 100%;
        }

        // button:hover {
        //     border-radius: 0px;
        //     transition: .3s;
        // }
    }

    // span {
    //     margin: 0 10px;
    //     margin-bottom: 80px;
    //     color: $teal;
    //     font-weight: 500;
    //     font-size: 3rem;
    //     text-shadow: 0 0 3px $teal;
    // }

    // button {
    //     width: 100px;
    //     height: 30px;
    //     background-color: $green;
    //     outline: none;
    //     border: none;
    //     border-radius: 7px;
    //     transition: .3s;
    //     cursor: pointer;
    // }

    // button:hover {
    //     transform: scale(1.2);
    //     box-shadow: 0 0 20px $green;
    //     transition: .3s;
    // }
    transition: .3s;
}

.category:hover {
    transform: scale(1.02);
    transition: .3s;
}