.wrong-question {
    position: relative;
    border: 1px solid rgb(54, 54, 54);
    border-radius: 5px;
    margin: 10px 30px;
    text-align: center;
    display: flex;
    font-size: 1.3rem;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(255, 94, 94, 0.76);
}

#stats-correct {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.795);
    text-decoration: underlin
}

#stats-correct-pre-label {
    font-size: 1.2;
    padding: 20px;
}